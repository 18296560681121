import React, {useEffect, useRef} from "react"
import { useState } from "react";
import { Container } from "react-bootstrap";
import {Link} from "gatsby"

// Components
import {GetSubMenus} from "../../queries/common_use_query";
import { menuLinks } from "../common/menuUtils";
import ScrollContainer from 'react-indiana-drag-scroll'

// Styles
import './ScrollTabs.scss';

const ScrollTabs = ( { children, menu_id, Page_Name, GQLPage } ) => {
    const { loading, error, data } = GetSubMenus(menu_id);

    const stickyRef = useRef();

    function getOffset( el ) {
        let _x = 0;
        let _y = 0;
        while( el && !isNaN( el.offsetLeft ) && !isNaN( el.offsetTop ) ) {
            _x += el.offsetLeft - el.scrollLeft;
            _y += el.offsetTop - el.scrollTop;
            el = el.offsetParent;
        }
        return { top: _y, left: _x };
    }

    useEffect(() => {
        window.addEventListener("scroll", windowScroll);
        return () => window.removeEventListener("scroll", windowScroll);
    },[])
    // Property details sticky nav
    const windowScroll = () => {
        const top = getOffset(stickyRef.current).top - 50;
        // const bottomSticky = getOffset(stickyRef.current).top + 2300;
        //const top = 750;
        //const bottomSticky = 1500;

        // if(window.pageYOffset > top && window.pageYOffset < bottomSticky){

        //console.log("pageYOffset", window.pageYOffset, top)
        // if(window.pageYOffset > top){
        //   stickyRef.current.classList.add('is-sticked');
        // } else {
        //   stickyRef.current.classList.remove('is-sticked');
        // }
    }

    //console.log("menu_id", menu_id)

    return (
        <div className="scroll-tab-wrapper" ref={stickyRef}>
            <TabHeader
                headerTabs={ data?.allMenus }
                setSelectedTab={ Page_Name }
                GQLPage={GQLPage}
            />
            <TabBody>
                { children }
            </TabBody>
        </div>
    )
}

const TabHeader = ( { headerTabs = [], setSelectedTab, GQLPage } ) => {

    //console.log("headerTabs", setSelectedTab);
    
    return (
        <div className="scroll-tab-header">
            <Container>
                <div className="tab_scroll">
                <ScrollContainer hideScrollbars={true}>
                    <div className="scroll-tabs">

                        { headerTabs?.filter(item => item.Sub_Menu === null).map( ( headerItem, headerIndex ) => {
                            const isSelected = ( headerItem.Page_Name === setSelectedTab );
                            return (
                                <>
                                {headerIndex === 0 && headerItem.Parent_Menu.Label === "About" && <Link to={'/'+headerItem.Parent_Menu.Primary_URL+"/"} className={ setSelectedTab === "About Resiland" ? 'selected' : '' }>
                                    { headerItem.Parent_Menu.Label }
                                </Link>
                                }
                                <Link to={menuLinks(headerItem)}
                                    key={ headerIndex }
                                    className={ isSelected ? 'selected' : '' }
                                >
                                    { headerItem.Label }
                                </Link>
                                </>
                            )
                        } ) }
                    </div>
                </ScrollContainer>
                </div>
            </Container>
        </div>
    )
}

const TabBody = ( { children } ) => {
    return (
        <div className="scroll-tab-body">
            { children }
        </div>
    )
}

export default ScrollTabs
